<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Knowlarity Creds Input -->
    <v-card class="rounded-lg secondarygrad">
      <v-row>
        <v-col>
          <v-card
            color="transparent"
            elevation="0"
            style="padding: 10px; text-align: center !important"
          >
            <v-card-text style="margin-bottom: 25px">
              <!-- <h2 style="margin-bottom: 30px">
                      Lorem ipsum
                    </h2> -->
              <div align="center" style="margin-bottom: 10px; margin-top: 20%">
                <h1 style="margin-bottom: 40px">Godial + Quikr</h1>
              </div>
              <div style="padding: 5px 15px; text-align: center">
                <!-- <h5
                        style="
                          line-height: 17px;
                          font-weight: 500;
                          font-size: 14px;
                        "
                      >
                        How to use?
                      </h5> -->

                <div style="text-align: left">
                  <p
                    style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          "
                  >
                    How to use?
                  </p>
                  <p>
                    1. Enable Quikr
                    <br />
                    2. Select a list where you want to receive the leads, and
                    click save
                    <br />
                    3. Now you need to forward all quikr emails to the
                    Forwarding address
                    <br />
                    4. If you are using Gmail you will need to create a filter
                    and forward it, also your gmail verification email will get
                    forwarded to your manager account email
                    <br />
                    5. That is all you need to setup a quikr integration via
                    email
                  </p>
                </div>
                <!-- <p class="text-left">
                        For more details visit :
                        <a
                          target="_blank"
                          href="https://help.indiamart.com/knowledge-base/lms-crm-integration/"
                        >
                          this link</a
                        >
                      </p> -->
              </div>
            </v-card-text>
            <v-footer color="transparent" absolute>
              <v-flex style="text-align: center !important">
                <p
                  style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        "
                >
                  Feel free to reach out to us at
                  <a href="mailto:support@godial.cc">support@godial.cc</a>
                  if you are interested in these features
                </p>
              </v-flex>
            </v-footer>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat class="transparent">
            <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
              <v-layout row wrap>
                <v-flex
                  d-flex
                  justify-center
                  class="mt-10"
                  style="padding-top: 22px"
                  >Enable Quikr
                </v-flex>
                <v-flex class="mt-10" d-flex justify-center>
                  <v-switch v-model="quikr.enabled" color="primary"></v-switch>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
          <v-card
            color="transparent"
            elevation="0"
            style="margin-top:25px; padding: 15px 50px; text-align: center"
          >
            <v-card-title primary-title class="justify-center"
              >Quikr Credentials</v-card-title
            >

            <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
              <v-text-field
                v-model="quikr.config.email"
                readonly
                label="Forwarding Email"
                type="text"
                rows="3"
                prepend-inner-icon="email"
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
              <!-- <v-select
                      v-bind:disabled="!indiamart.enabled"
                      v-model="indiamart.selectedList"
                      label="Mobile Key"
                      type="text"
                      outlined
                      dense
                      prepend-inner-icon="vpn_key"
                    ></v-select> -->
              <v-autocomplete
                v-model="quikr.listId"
                :disabled="!quikr.enabled"
                class="pt-1"
                :items="lists"
                item-text="name"
                item-value="id"
                label="Lists"
                prepend-inner-icon="view_list"
                autocomplete="false"
                outlined
                dense
              />
            </v-flex>

            <v-card-text>
              <v-flex
                xs12
                sm10
                offset-sm1
                md10
                offset-md1
                style="margin-bottom: 10px"
              >
                <v-btn color="primarygrad" dark block @click="setQuikr"
                  >Save</v-btn
                >
              </v-flex>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

export default {
  data() {
    return {
      quikr: {
        type: "quikr",
        config: {
          email: "",
        },
        listId: "",
        companyId: "",
        enabled: false,
      },
      loader: false,
      lists: [],
    };
  },

  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  watch: {},
  created() {
    this.fetchLists();
    this.getQuikrDetails();
    this.quikr.companyId = this.companyDetails.id;
  },
  methods: {
    async getQuikrDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=quikr`
      );

      if (resp.body.length > 0) this.quikr = resp.body[0];
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    async setQuikr() {
      this.loader = true;

      try {
        this.quikr.companyId = this.companyDetails.id;
        this.quikr.type = "quikr";
        this.quikr.config.email = `qu-${this.companyDetails.id}@mg.godial.cc`;
        var res = {};

        //If new user
        if (typeof this.quikr.id == "undefined") {
          // Add Indiamart Details In Company Data
          res = await this.$http.post(
            `${this.ENDPOINT}/integrations`,
            this.quikr
          );
        } else {
          var data = JSON.parse(JSON.stringify(this.quikr));
          delete data.id;
          res = await this.$http.put(
            `${this.ENDPOINT}/integrations/${this.quikr.id}`,
            data
          );
        }

        // Success Message
        if (res.status == 200) {
          // Set Company Object for Store dispatch
          this.getQuikrDetails();
          this.loader = false;

          try {
            fb.log("quikr_added");
          } catch (e) {
            console.log("Error in FB log", e);
          }

          return this.$swal({
            type: "success",
            text: "Quikr Added to Godial Successfully",
          });
        } else {
          return this.$swal({
            type: "warning",
            text: "Something Went Wrong",
          });
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
        return this.$swal({
          type: "warning",
          text: err,
        });
      }
    },
  },
};
</script>

<style></style>
